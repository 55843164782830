// This is a main JavaScript file that will ll be compiled into /javascripts/site.js
//
// Any JavaScript file in your site folder can be referenced here by using import or require statements.
// Additionally, you can import modules installed via your package.json file.
//
// For example:
// import './fileName'
//
// To learn more, visit https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import

let siteNavList = document.querySelector('.site-nav__list');
let currentLocationPathname = window.location.pathname;

// check if it exists in case of splash page
if(siteNavList) {
  siteNavList = siteNavList.getElementsByTagName('a');

  for (var i = 0; i < siteNavList.length; i++) {
    var link = new URL(siteNavList[i].href);
    if(currentLocationPathname === link.pathname) {
      siteNavList[i].classList.add('active');
      setPageNav(i);
    }
  }
}
/**
 * setPageNav()
 * @param {integer} siteNavLocationIndex
 * Based on the current site navigation index set the prev/next arrow links
 *
 */
function setPageNav (siteNavLocationIndex) {
  let pageNavs = document.querySelectorAll('.page-nav');

  for(var i = 0; i < pageNavs.length; i++) {
    let pageNav = pageNavs[i];
    let pageNavPrev = pageNav.querySelector('.page-nav__prev');
    let pageNavNext = pageNav.querySelector('.page-nav__next');
    let prev = siteNavLocationIndex;
    let next = siteNavLocationIndex;

    if(prev == 0) {
      // first page so no prev
      pageNavPrev.setAttribute('aria-disabled', true);
      pageNavPrev.classList.add('disabled');
      pageNavPrev.href = "#";
      pageNavPrev.onclick = function(e) {e.preventDefault();}
    } else {
      prev = prev - 1;
      pageNavPrev.href = siteNavList[prev].href;
    }
    if(next + 1 == siteNavList.length) {
      // last page so no next
      pageNavNext.setAttribute('aria-disabled', true);
      pageNavNext.classList.add('disabled');
      pageNavNext.href = "#";
      pageNavNext.onclick = function(e) {e.preventDefault();}
    } else {
      next = next + 1;
      pageNavNext.href = siteNavList[next].href;
    }
  }




}



var colorSwatch = Vue.component('color-swatch', {
  template: `
    <div class="color-swatch">
      <div class="color-swatch__primary">
        <div class="color" :style="{backgroundColor: primaryColor}"></div>
        <div class="label">
          <span>{{name}}</span>
          <span>{{primaryColor}}</span>
        </div>
      </div>
      <template v-if="hasTints">
      <div v-for="tint in tints" class="color-swatch__tint">
        <div class="color" :style="{backgroundColor: tint}"></div>
        <div class="label">
          <span>{{tint}}</span>
        </div>
      </div>
      </template>
    </div>
  `,
  props: ['name', 'values'],
  computed: {
   colors: function() {
     return this.values.trim().split(",");
   },
   primaryColor: function () {
    return this.colors[0];
   },
   tints: function() {
    if(this.hasTints) {
      return this.colors.slice(1);
    } else {
      return false;
    }
   },
   hasTints: function () {
    return this.colors.length > 1;
   }
  }
});


var colorConcept = Vue.component('color-concept', {
  template: `
    <div class="color-concept">
    <div class="color-concept__primary" :style="{backgroundColor: primary}"></div>
    <div class="color-concept__secondary" :style="{backgroundColor: secondary}"></div>
    <div class="color-concept__tertiary" :style="{backgroundColor: tertiary}"></div>
    </div>
  `,
  props: ['primary', 'secondary', 'tertiary'],
});


var app = new Vue({
  el: '#app',
  data: {
    navShow: false,
    vueApp: vueApp,
  },
  methods: {
    showNav: function(event) {
      this.navShow = !this.navShow;
    }
  }
});



fitty('.content-header h1', {
  minSize: 10,
  maxSize: 96
});

fitty('.editor h1', {
  minSize: 10,
  maxSize: 96
});
